import React from 'react';
import { MDText } from 'i18n-react';

import { Hyperlink, InfoBox } from '@motorway/mw-highway-code/alpha';

import Texts from './VehiclePayment.texts.json';
import {
	LatestPostSaleOffer,
	Payment,
	PaymentInitialStateReturn,
	PriceBreakdown,
	VehiclePaymentPageProps,
} from './VehiclePayment.types';

const LocalT = new MDText(Texts);

const ENTRY_VIEW_RETURN: PaymentInitialStateReturn = {};

const PROCESSING_VIEW_RETURN: PaymentInitialStateReturn = {
	journey: 'green',
	offerApproved: true,
	processingRevisit: true,
};

const SUCCESS_VIEW_RETURN: PaymentInitialStateReturn = {
	journey: 'approved',
	offerApproved: true,
};

const ERROR_VIEW_RETURN: PaymentInitialStateReturn = {
	hasError: true,
	journey: 'error',
};

export const PHONES = {
	ADD_FUNDS: '020 4578 0846',
	DISCOVERY_PAGE: '020 4578 0846',
	GENERAL_PAYMENTS_ERROR: '020 4578 0846',
	INCORRECT_FIGURES: '020 4578 0846',
	NEGATIVE_EQUITY: '020 4578 0846',
	OTP_SETUP: '020 3885 6242',
	PAYMENT_ERROR: '020 4578 0846',
	PRICING_DETAILS: '020 4578 0846',
	REVISED_OFFER: '020 3885 7079',
	WALLET_ERROR: '020 4578 0846',
	WITHDRAW_FUNDS: '020 4578 0846',
};

export const SUPPORT_EMAILS = {
	PAYMENTS: 'payments@motorway.co.uk',
};

export const resolveApprovedLatestOfferInitialState = (payment?: Payment): PaymentInitialStateReturn => {
	switch (payment?.status) {
		case 'done': {
			return SUCCESS_VIEW_RETURN;
		}
		case 'failed':
		case 'errored': {
			return ERROR_VIEW_RETURN;
		}
		default: {
			return PROCESSING_VIEW_RETURN;
		}
	}
};

// eslint-disable-next-line max-len
export const resolvePendingLatestOfferInitialState = (latestOffer?: LatestPostSaleOffer): PaymentInitialStateReturn => {
	switch (latestOffer?.type) {
		case 'closing_bid': {
			return ENTRY_VIEW_RETURN;
		}
		case 'counter_offer': {
			if (latestOffer?.userType === 'agent') {
				if ('dealer' in latestOffer.approvals) {
					return PROCESSING_VIEW_RETURN;
				}

				return ENTRY_VIEW_RETURN;
			}

			return PROCESSING_VIEW_RETURN;
		}
		default: {
			return ENTRY_VIEW_RETURN;
		}
	}
};

// Based on: https://www.notion.so/motorway/Doc-What-should-the-user-see-during-payment-process-66693dcbb6ef4f079e7776d5494cf538
export const resolveVehiclePaymentInitialState = ({
	latestOffer,
	payment,
}: Pick<VehiclePaymentPageProps, 'payment' | 'latestOffer'>): PaymentInitialStateReturn => {
	switch (latestOffer?.state) {
		case 'pending': {
			return resolvePendingLatestOfferInitialState(latestOffer);
		}
		case 'approved': {
			return resolveApprovedLatestOfferInitialState(payment);
		}
		case 'rejected': {
			return ERROR_VIEW_RETURN;
		}
		default: {
			return ENTRY_VIEW_RETURN;
		}
	}
};

export const shouldDisplayNEPage = (
	hasNegativeEquity: boolean,
	payment: VehiclePaymentPageProps['payment'],
): boolean => {
	const hasSuccessManualPayment = payment && payment.status === 'done';
	return hasNegativeEquity && !hasSuccessManualPayment;
};

export const hasNegativeEquity = (
	priceBreakdown: PriceBreakdown[] | LatestPostSaleOffer['priceBreakdown'] | null | undefined,
): boolean => {
	if (!priceBreakdown) {
		return false;
	}

	if (Array.isArray(priceBreakdown)) {
		return priceBreakdown.some((item) => item.type === 'seller_amount' && item.amount < 0);
	}

	return priceBreakdown?.seller_amount?.amount < 0;
};

export const resolveActions = (actions: (React.ReactElement | null | undefined)[]): React.ReactElement[] => {
	const validActions = actions.filter<React.ReactElement>((action): action is React.ReactElement => Boolean(action));

	return validActions;
};

export const PaymentNonTerminalError: React.FC<{ loading: boolean }> = ({ loading }) => {
	return (
		<InfoBox
			content={LocalT.translate('error.nonTerminal.desc', {
				phone: <Hyperlink href={`tel:${PHONES.GENERAL_PAYMENTS_ERROR}`} label={PHONES.GENERAL_PAYMENTS_ERROR} />,
			})}
			data-loading={loading}
			heading={LocalT.translate('error.nonTerminal.title')}
			variant="error"
		/>
	);
};

export const sleepWithAbort = (
	ms: number,
	opts: {
		forceDelay?: boolean;
		signal: AbortSignal | null;
	},
): Promise<void> => {
	const { forceDelay = false, signal } = opts;

	const delay = ms ?? 3000;

	return new Promise((resolve) => {
		if (delay === 0) {
			resolve();
			return;
		}

		if (signal?.aborted && !forceDelay) {
			resolve();
			return;
		}

		const timeoutId = setTimeout(() => {
			resolve();
		}, delay);

		if (signal) {
			signal.addEventListener(
				'abort',
				() => {
					clearTimeout(timeoutId);
					resolve();
				},
				{ once: true },
			);
		}
	});
};
