import axios from 'Services/axios';
import { resolveParams } from 'Services/services.helpers';
import isomorphicApi from 'Utilities/helpers/isomorphicApi';

import { doesUserHavePaymentsSessionWhenIsomorphicApiServerCall } from '../../auth/paymentsSessionHelper';
import { findDealerToMotorwayTransfer } from '../../server/api/mappers/payment';
import { API_ROUTES } from '../../utilities/routes';

export const getLatestPostSaleOffer = async ({ enquiryId, signal = undefined }, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/postSaleOffers/latest${resolveParams({ enquiryId })}`, {
				signal,
			});
			return res.data;
		},
		server: async (api) => {
			const res = await api.getLatestPostSaleOffer(enquiryId);
			return res.data;
		},
	});

export const approvePostSaleOffer = async (enquiryId, verificationCode) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/postSaleOffers/approve`, { enquiryId, verificationCode });
	return res.data;
};

export const createPostSaleOffer = async (payload, ctx = {}) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.post(`${API_ROUTES.PAYMENTS}/postSaleOffers/create`, payload);
			return res.data;
		},
		server: async (api) => {
			const res = await api.createPostSaleOffer(payload);
			return res.data;
		},
	});

export const getPayment = async ({ enquiryId, signal = undefined }, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/payments${resolveParams({ enquiryId })}`, { signal });
			return res.data;
		},
		server: async (api) => {
			const res = await api.getPayment(enquiryId);

			return findDealerToMotorwayTransfer(res.data);
		},
	});

export const generateOTP = async ({ enquiryId, logs, otpAction, source }) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/generate`, { enquiryId, logs, otpAction, source });
	return res.data;
};

export const verifyOTP = async ({ logs, source, verificationCode }) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/verify`, { logs, source, verificationCode });
	return res.data;
};

export const isUserOTPAuthorised = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/is-user-authorised`);
			return res.data;
		},
		server: async () => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			return { status: 200 };
		},
	});

export const getTransactions = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/transactions${resolveParams(payload)}`);
			return res;
		},
		server: async (api) => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			const res = await api.getTransactions(payload);
			return res;
		},
	});

export const getSingleTransaction = async (id, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/transactions/${id}`);
			return res.data;
		},
		server: async (api) => {
			const res = await api.getSingleTransaction(id);
			return res;
		},
	});

/*
 payload: GetPaymentAccountsApiRequestParams
 */
export const getPaymentAccounts = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/paymentAccounts${resolveParams(payload)}`);
			return res.data;
		},
		server: async (api) => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			const res = await api.getPaymentAccounts(payload);
			return res.data[0];
		},
	});

/*
 payload: CheckFundsApiRequestParams
 */
export const checkFunds = async (payload, options) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/checkFunds`, payload, options);
	return res.data;
};

export const requestCallback = async (payload) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/request-callback`, payload);
	return res.data;
};
