import dayjs from 'dayjs';
import { NextRouter } from 'next/router';

import { matchPath } from 'Components/shared/Wizard/Router.helpers';
import ROUTES from 'Utilities/routes';

import { OtpAction } from './OTPContent.types';

export const TIME_BETWEEN_SMS = 1000 * 60 * 5;

export type OTPSource =
	| 'approvePostSaleOffer'
	| 'createPostSaleOffer'
	| 'accessSensitiveData'
	| 'withdrawFunds'
	| 'setup';

export type UseOTPProps = {
	disabled?: boolean;
	enquiryId: number | undefined;
	otpAction?: OtpAction;
	source: OTPSource;
};
export interface UseOTPReturn {
	countDown: number;
	getCode: () => void;
	readableCountDown: string;
	requestInProgress: boolean;
	resendButtonAvailable: boolean;
	stopCounter: () => void;
}

export const getReadableFormat = (duration: number): string => {
	const dayDuration = dayjs.duration(duration);
	let result = '';
	if (dayDuration.minutes() > 0) {
		result += `${dayDuration.format('m')}min `;
	}
	if (dayDuration.seconds() > 0) {
		result += `${dayDuration.format('s')}sec`;
	}

	return result.trim();
};

const ROUTE_VALUES = Object.values(ROUTES);

// Find the matching path/asPath for a given page
export const matchOTPPath = (key: string, page?: string): Record<string, string> => {
	if (!page) return {};

	let matchingPath = {};
	ROUTE_VALUES.some((route) => {
		const routeMatch = matchPath(page.split('?')[0], { path: route.as() });
		if (routeMatch) {
			matchingPath = { [`${key}Path`]: routeMatch.path };
		}

		return Boolean(routeMatch);
	});

	return {
		[`${key}AsPath`]: page,
		...matchingPath,
	};
};

export const getOTPLogParams = (router: NextRouter): Record<string, string> => ({
	currentAsPath: router.asPath,
	currentPath: router.pathname,
	...matchOTPPath('from', router.query.from?.toString()),
	...matchOTPPath('to', router.query.to?.toString()),
});
